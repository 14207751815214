import * as ActionTypes from './ActionTypess';
import web3 from '../web3.js';
import TenupSmart from '../ethereum/TenupSmart';

export const logInRequest = (address) => dispatch => {
  console.log('here');
  return fetch(`${process.env.REACT_APP_BASE_URL}/users/${address}`)
    .then(res => res.json())
    .then(async res => {
      if (res.success) {
        var balance = await TenupSmart.methods.balanceOf(address).call();
        res.user.balance = balance;
        return dispatch(login(res.user));
      }
      else{
        return dispatch(logout(null))
      };
    });
}
const login = (user) => ({
    type: ActionTypes.LOGGED_IN,
    payload: user
})
export const logout = (id) => ({
    type: ActionTypes.LOGGED_OUT,
    payload: id
})

export const updateNonce = (user) => ({
  type: ActionTypes.UPDATE_NONCE,
  payload: user
})

export const getAllSwaps = (address) => (dispatch) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/swaptx/all/${address}`)
    .then(res => res.json())
    .then(async res => {
      if (res.success) {
        return dispatch(setSwapTx(res.swaptxs));
      }
    });
}

export const getSwapTx = (address) => dispatch => {
  console.log('here');
  return fetch(`${process.env.REACT_APP_BASE_URL}/swaptx/${address}`)
    .then(res => res.json())
    .then(async res => {
      if (res.success) {
        return dispatch(setSwapTx(res.swaptxs));
      }
    });
}
const setSwapTx = (s) => ({
  type: ActionTypes.ADD_SWAP_TX,
  payload: s
});

export const swapUpdateRequest = (addressAndAmount) => dispatch => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({addressAndAmount});
  console.log(raw)
  var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
  };
    
  return fetch(`${process.env.REACT_APP_BASE_URL}/swaptx/done`, requestOptions)
          .then(res => res.json())
          .then(res => {
              if (res.success) {
                return dispatch(replaceSwaps(addressAndAmount));
              }
              else{
                alert('Update Failed!');
              }
          })
}
const replaceSwaps = (s) => ({
  type: ActionTypes.REPLACE_SWAPS,
  payload: s
})