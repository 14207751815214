import React, { useState, useEffect } from 'react';

export default function Timer({date}){
  const calculateTimeLeft = () => {

    let year = new Date().getFullYear();

    const difference = +new Date(2021,8,6,12,30,0,0) - +new Date();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [year] = useState(new Date().getFullYear());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval, index) => {
    // if (!timeLeft[interval]) {
    //   return;
    // }
    if (index === 0){
      timerComponents.push(
        <span>
          <span style={{fontSize: '20px'}}>
            {timeLeft[interval]}
          </span>
          <span style={{fontSize: '16px'}}>
            {'d '}
          </span>
        </span>
      );
    }
    else{
      timerComponents.push(
        <span>
          : <span style={{fontSize: '20px'}}>{timeLeft[interval]}</span>
          <span style={{fontSize: '16px'}}>
            {index === 1 && 'h '}
            {index === 2 && 'm '}
            {index === 3 && 's'}
          </span>
        </span>
      );
    }
  });
  return (
    <div style={{color: 'white', textAlign: 'center'}}>
      <h2>Token Participation<br/></h2>
      <h5 style={{display: 'inline-block'}}> Ends in: </h5>
      <p>
        {/* <h1>HacktoberFest {year} Countdown</h1> */}
        {/* <h2>With React Hooks!</h2> */}
        {timerComponents.length ? timerComponents : <span>Time's up!</span>}
      </p>
    </div>
  );
}
