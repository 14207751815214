import * as ActionTypes from '../ActionTypess';

export const SwapTx = (state = {
    isLoading: false,
    errMess: null,
    swapTx: []
}, action) => {
        switch(action.type) {
            case ActionTypes.ADD_SWAP_TX:
                return {...state, isLoading: false, errMess: null, swapTx: action.payload}
            case ActionTypes.REPLACE_SWAPS:
                var final = [];
                state.swapTx.forEach(a => {
                    var needToUpdate = action.payload.some((aa) => aa.txId === a.tx_received && aa.address === a.user.address);
                    if (needToUpdate){
                        console.log("updating", a);
                        a.status = 'Swapped';
                    }
                    final.push(a);
                })
                return {...state, isLoading: false, errMess: null, swapTx: final}
            case ActionTypes.LOGGED_OUT:
                return {...state, isLoading: false, errMess: null, swapTx: []}    
            default:
                return state;
        }
};