import React from "react";
import { Link } from 'react-router-dom';

import { routes } from "../../shared/constants";

const texts = {
    title: "404",
    subTitle: "Page not found.",
    backToHomepage: "Back to homepage",
}

const Page404Module = () => {
    return (
        // <div>
        //     <p>{texts.title}</p>
        //     <p>{texts.subTitle}</p>
        //     <div>
        //         <Link to={routes.homepage}>{texts.backToLogin}</Link>
        //     </div>
        // </div>
        <div class="page-wrap d-flex flex-row align-items-center">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-12 text-center">
                        <span class="display-1 d-block">404</span>
                        <div class="mb-4 lead">The page you are looking for was not found.</div>
                        <a href="/wallets" class="btn btn-link">Back to Home</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Page404Module;