import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Badge, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeadingModule from '../components/Layout/HeadingComponent/Heading';
import Page404 from '../components/Page404';
import Timer from "../components/Timer/timer";
import ToggleSwitch from "../components/Toggle/toggle";
import web3 from "../web3";
import USDTContract from "../ethereum/USDTContract";
import PresaleContract from '../ethereum/Presale';
import TenupSmart from "../ethereum/TenupSmart";
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab'
import Web3 from "web3";
import abi from '../ethereum/tokensale.json';
import { logInRequest, logout } from "../store/ActionCreators";


const Presale = (props) => {
  const id = "togglerbtn";
  const [checked, setChecked] = useState(false);
  const [loginFailed, setLoginErr] = useState(false);
  const [loginSuccess, setLoginSucc] = useState(false);
  const [show, setShow] = useState(false);
  const [ethBalance, setEthBalance] = useState('0');
  const [percent, setPercent] = useState('0');
  // const [usdtBalance, setUsdtBalance] = useState('0');
  // const [usdtRate, setUSDTRate] = useState('0');
  const [ethRate, setEthRate] = useState('0');
  // const [usdtContr, setUsdtContr] = useState('0');
  const [ethContr, setEthContr] = useState('0');
  const [tokenSold, setTokensSold] = useState('0');
  const [discountCounter, setDiscountCounter] = useState('0');
  const [lessThanForty, setLessThanForty] = useState(false);
  const [insufficient, setInsufficient] = useState(false);
  const [errDisp, setErrDisp] = useState(false);
  const [errMess, setErrMess] = useState('');
  const [ethRaised, setEthRaised] = useState('0');
  const [amountToContribute, setAmtToContribute] = useState(0);

  const getAddress = async (address) => {
    const ethBalance = await web3.eth.getBalance(address);
    // const usdtBalance = await USDTContract.methods.balanceOf(address).call();
    let ethContributed = await PresaleContract.methods.contributer(address).call();
    // let usdtContributed = await PresaleContract.methods.contributerUSD(address).call();
    console.log(ethContributed);
    setEthContr(ethContributed);
    // setUsdtContr(usdtContributed);
    setEthBalance(ethBalance);
    // setUsdtBalance(usdtBalance);
  }
  // const setPercent = (a) => {
  //     if (props.authedUser.authedUser.address){
  //         setAmtToContribute((parseFloat(web3.utils.fromWei(props.authedUser.authedUser.balance))*a).toFixed(3));
  //     }
  //     else{
  //         alert('Connect Wallet First');
  //     }
  // }
  const totalTokensInPresale = 40000000;
  const totalDiscounts = 500;
  const sentInDiscounts = 1762589;

  useEffect(() => {
    if (props.authedUser.authedUser.address){
      getAddress(props.authedUser.authedUser.address);
    }
  }, [props.authedUser.authedUser]);

  useEffect(() => {
    // let ethRate = await PresaleContract.methods.getEthRate
    initt();
  }, []);

  const initt = async () => {
    let ethrate = await PresaleContract.methods.ethRate().call();
    // let usdtrate = await PresaleContract.methods.rate().call();
    let tokSold = await PresaleContract.methods.totalTokensSold().call();
    let discount = await PresaleContract.methods.counter().call();
    let ethraise = await PresaleContract.methods.ethRaised().call();
    console.log(ethrate);
    console.log(tokSold, parseFloat(web3.utils.fromWei(tokSold)) + sentInDiscounts);
    console.log(discount);
    setDiscountCounter(discount);
    setTokensSold(parseFloat(web3.utils.fromWei(tokSold)) + sentInDiscounts);
    setEthRate(web3.utils.fromWei(ethrate));
    setEthRaised(web3.utils.fromWei(ethraise));
    // setUSDTRate(usdtrate/(10**6));  
  }

  const copyString = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
    
  const connectMetamask = async () => {
    console.log('here');
    if (!window.ethereum){
        setErrMess('Install Metamask');
        setErrDisp(true);
    }
    else if (window.ethereum.chainId !== process.env.REACT_APP_CHAIN_ID){
        setErrMess('Connect to Mainnet');
        setErrDisp(true);
    }
    else{
        var accs = await window.ethereum.request({ method: 'eth_requestAccounts' });
        if (accs.length > 0){
          props.logInRequest(accs[0])
          .then(r => {
              console.log(r);
              // props.getSwapTx(accs[0]);
          })
        }
        else{
            setErrMess('Connect to Metamask');
            setErrDisp(true);
        }
    }
  }

  const contributeInPresale = async () => {
    let v = document.getElementById('contributedAmt').value;
    console.log(v);
    console.log(PresaleContract);
    // if (checked){
    let presaleCorrect = PresaleContract;
    if (web3.fromInfura){
      connectMetamask();
      if (!window.ethereum){
        setErrMess('No Wallet Detected');
        setErrDisp(true);
      }
      else if (window.ethereum.chainId !== process.env.REACT_APP_CHAIN_ID){
        setErrMess('Connect to MAINNET');
        setErrDisp(true);
      }
      else{
        let newWeb3 = new Web3(window.ethereum);
        presaleCorrect = new newWeb3.eth.Contract(abi, process.env.REACT_APP_PRESALE_ADDRESS);
        web3.fromInfura = false;
        console.log("infura removed");
      }
    }
    var totalTokens = v/ethRate;
    if (totalTokens > 40){
      if (totalTokens <= 29000){
        try{
          const r = await presaleCorrect.methods.buyTokens().send({
            from: props.authedUser.authedUser.address,
            value: web3.utils.toWei(v)
          });
          const ethBalance = await web3.eth.getBalance(props.authedUser.authedUser.address);
          let ethContributed = await PresaleContract.methods.contributer(props.authedUser.authedUser.address).call();
          let tokSold = await PresaleContract.methods.totalTokensSold().call();
          let discount = await PresaleContract.methods.counter().call();
          let ethraise = await PresaleContract.methods.ethRaised().call();
          setEthContr(ethContributed);
          setEthBalance(ethBalance);
          setTokensSold(parseFloat(web3.utils.fromWei(tokSold)) + sentInDiscounts);
          setEthRaised(web3.utils.fromWei(ethraise));
          setDiscountCounter(discount);
          console.log(r);
          setAmtToContribute(0);
          setLoginSucc(true);
        }
        catch(e){
          if (web3.fromInfura){
            console.log("Infura connected", e);
          }
          else{
            console.log(e);
          }
          setErrMess('Error: Transaction Failed');
          setErrDisp(true);
        }  
      }
      else{
        setErrMess('You cannot buy less than 29000 TUP in single transaction');
        setErrDisp(true);
      }
    }
    else{
      setErrMess('You cannot buy less than 40 TUP');
      setErrDisp(true);
    }
    // }
    // else{
    //   var totalTokens = v/usdtRate;
    //   console.log(totalTokens);
    //   if (totalTokens > 40){
    //     if (parseInt(usdtBalance) > v*(10**6)){
    //       setShow(true);
    //       const aprRes = await USDTContract.methods.approve(process.env.REACT_APP_PRESALE_ADDRESS, v*(10**6)).send({
    //         from: props.authedUser.authedUser.address
    //       })
    //       console.log(aprRes);
    //       setShow(false);
    //       const buyRes = await PresaleContract.methods.buyTokensUSDT(v*(10**6)).send({
    //         from: props.authedUser.authedUser.address
    //       })
    //       console.log(buyRes);
          
    //       const usdtBalance = await USDTContract.methods.balanceOf(props.authedUser.authedUser.address).call();
    //       let usdtContributed = await PresaleContract.methods.contributerUSD(props.authedUser.authedUser.address).call();
    //       let tupremaining = await TenupSmart.methods.balanceOf(process.env.REACT_APP_PRESALE_ADDRESS).call();
    //       console.log(usdtBalance, usdtContributed, tupremaining);
    //       setAmtToContribute(0);
    //       setUsdtContr(usdtContributed);
    //       setTokensSold(web3.utils.fromWei(tokenSold));
    //       setUsdtBalance(usdtBalance);    
    //     }
    //     else{
    //       setInsufficient(true);
    //     }
    //   }
    //   else{
    //     setLessThanForty(true);
    //   }
  
    // }
  }
    return (
        <>
            <section className="zl_account_support_page">
                <HeadingModule name={'Presale'} />
                <div className="container">
                  <div className="zl_setting_list_items" style={{padding: '25px', borderRadius: '30px', border:'1px solid #3a4562', marginBottom: '15px', backgroundColor: '#353f5e', boxShadow: '-10px 48.6px 140px rgba(23, 18, 43, 0.45)'}}>
                        <div className="zl_setting_items_heading_peregraph" >
                            <h2 style={{color: '#eaebee'}}>How it works?</h2>
                            <p style={{color: '#a1a7bb', fontSize: '15px', lineHeight: '30px', fontWeight: 'normal'}}>A very simple and easy method for participation in a presale.<br></br>
                            Please follow the steps: <br/>
                            1. Connect you metamask wallet. <br/>
                            {/* 2. Choose the predefined contribution method ETH or USDT<br/> */}
                            2. Enter the contribution amount in ETH.<br/>
                            3. Press Contribute.<br/>
                            For more information please watch this video: <a href="https://www.youtube.com/watch?v=QkLSdCMO4FY" target="__blank">Presale Steps Guide</a><br/>
                            </p>
                        </div>
                    </div>
                  <div className="row">

                  <div className="col-12 col-sm-6 offset-sm-3 text-white" style={{display: 'flex', flexDirection: 'column', border: '2px solid #3A4562', padding: '20px 30px', borderRadius: '15px', marginLeft: 'auto', marginRight: 'auto', background: 'background: rgb(170 185 222)'}}>
                    <Timer />
                    <hr style={{color: 'white'}} />

                    <div>
                      {/* <div style={{textAlign: 'center'}}>
                      <span style={{color: 'white', fontWeight: 'bold', fontSize: '22px'}}>0 </span>
                      <span style={{color: '#C0C3CC', fontWeight: 'light', fontSize: '16px'}}>/ 6M TUP</span>
                      </div>
                      <ProgressBar striped style={{width: '425px'}} variant="info" now={(90000/6000000)*100} /> */}
                    </div>


                    <div style={{marginTop: '15px', border: '2px solid #3A4562', backgroundColor: 'rgb(77 104 173)', borderRadius: '10px', padding: '20px 25px'}}>
                        {/* <ToggleSwitch id="abbbcc" checked={checked} onChange={() => {setChecked(!checked);}} optionLabels={['ETH', 'USDT']} balance={checked ? ethBalance : usdtBalance} /> */}
                        <p style={{textAlign: 'right', marginBottom: '10px', fontSize: '80%'}}>Balance: {(parseFloat(web3.utils.fromWei(ethBalance))).toFixed(4)} </p>
                        <input placeholder="Enter your amount" id="contributedAmt" type="number" value={amountToContribute} onChange={(e) => setAmtToContribute(e.target.value)} />
                        {/* <div style={{marginTop: '10px', display: 'flex'}}>
                          <div style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <Badge className="amount-percent" style={{opacity: percent === '25' ? '1' : '0.5'}} onClick={() => setPercent('25')} bg="secondary">25%</Badge>
                            <Badge className="amount-percent" style={{opacity: percent === '50' ? '1' : '0.5'}} onClick={() => setPercent('50')} bg="secondary">50%</Badge>
                            <Badge className="amount-percent" style={{opacity: percent === '75' ? '1' : '0.5'}} onClick={() => setPercent('75')} bg="secondary">75%</Badge>
                            <Badge className="amount-percent" style={{opacity: percent === '100' ? '1' : '0.5'}} onClick={() => setPercent('100')} bg="secondary">100%</Badge>
                          </div>
                        </div> */}
                        <p style={{marginBottom: '0px', marginTop: '10px', textAlign: 'center'}}>You'll get:</p>
                        <h4 style={{textAlign: 'center'}}>{(ethRate && amountToContribute) ? (amountToContribute/ethRate).toFixed(4) : '0'} TUP</h4>
                        <div style={{display: 'flex', marginTop: '20px'}}>
                          <button id="contribute-btn" onClick={contributeInPresale}>Contribute</button>
                        </div>
                    </div>
                    <hr/>
                    <div className="container-fluid" style={{textAlign: 'center', marginTop: '15px', border: '1px solid #3A4562', backgroundColor: '#2f3c5d', borderRadius: '10px', padding: '20px 25px'}}>
                      <div className="row">
                        <span className="col-12" style={{backgroundColor: '#4d68ad', fontWeight: 'bolder', fontSize: '18px', borderRadius: '6px'}}>
                          Your Contributed Amount:
                        </span>
                        <div className="col-12 mt-3">ETH: {web3.utils.fromWei(ethContr)}</div>
                        {/* <div className="col-6 mt-3">USDT: {(usdtContr/(10**6))}</div> */}
                      </div>
                      <div className="row mt-4">
                        <span className="col-12"  style={{backgroundColor: '#4d68ad', fontWeight: 'bolder', fontSize: '18px', borderRadius: '6px'}}>
                          Token Rate:
                        </span>
                        <div className="col-12 mt-3">ETH: {ethRate}</div>
                        {/* <div className="col-6 mt-3">USDT: {usdtRate}</div> */}
                      </div>
                    </div>
                    
                    <div className="container-fluid" style={{marginTop: '15px', textAlign: 'center', border: '1px solid #3A4562', backgroundColor: '#2f3c5d', borderRadius: '10px', padding: '20px 25px'}}>
                      <div className="row">
                        <span  className="col-12"  style={{backgroundColor: '#4d68ad', fontWeight: 'bolder', fontSize: '18px', borderRadius: '6px'}}>
                          Tokens Status:
                        </span>
                        
                        <span  className="col-5 mt-3"  style={{backgroundColor: '#4d68ad', fontWeight: 'bolder', fontSize: '18px', borderRadius: '6px'}}>
                          ETH Raised:
                        </span>
                        <span  className="col-5 mt-3 offset-2"  style={{backgroundColor: '#4d68ad', fontWeight: 'bolder', fontSize: '18px', borderRadius: '6px'}}>
                          Tokens Sold:
                        </span>
                        <div className="col-5 mt-3">{parseFloat(ethRaised).toFixed(2)}</div>
                        <div className="col-5 mt-3 offset-2">{(parseFloat(tokenSold)).toFixed(2)}</div>


                        <span  className="col-sm-6 mt-3 offset-sm-3 col-12"  style={{backgroundColor: '#4d68ad', fontWeight: 'bolder', fontSize: '18px', borderRadius: '6px'}}>
                          Tokens Remaining:
                        </span>
                        <div className="col-12 mt-3">{(totalTokensInPresale - parseFloat(tokenSold)).toFixed(2)}</div>
                      </div>
                    </div>
                    <div className="container-fluid" style={{marginTop: '15px', textAlign: 'center', border: '1px solid #3A4562', backgroundColor: '#2f3c5d', borderRadius: '10px', padding: '20px 25px'}}>
                      <div className="row">
                        <span  className="col-12"  style={{backgroundColor: '#4d68ad', fontWeight: 'bolder', fontSize: '18px', borderRadius: '6px'}}>
                          Discount Remaining:
                        </span>
                        
                        <div className="col-12">{parseInt(discountCounter) > totalDiscounts ? 0 : totalDiscounts - parseInt(discountCounter)}</div>
                      </div>
                    </div>
                  </div>
                  </div>

                </div>
                
                <Snackbar open={errDisp} autoHideDuration={5000} variant="error" onClose={() => setErrDisp(false)}>
                  <Alert onClose={() => setErrDisp(false)} severity="error" variant="filled">
                    {errMess}
                  </Alert>
                </Snackbar>
                <Snackbar open={loginSuccess} autoHideDuration={5000} variant="success" onClose={() => setLoginSucc(false)}>
                  <Alert onClose={() => setLoginSucc(false)} severity="success" variant="filled">
                    Success: Transaction Success 
                  </Alert>
                </Snackbar>
                <Modal
                  show={show}
                  onHide={() => setShow(false)}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Body>
                      <div className="modal-loader"></div>
                      <h5>Dont close the window until the amount is approved.</h5>
                  </Modal.Body>
                </Modal>
            </section>
        </>
    );
}

const mapStateToProps = (state) => ({
  authedUser: state.authedUser,
  swapTx: state.swapTx
})
const mapDispatchToProps = (dispatch) => ({
  logInRequest: (address) => dispatch(logInRequest(address)),
  logout: () => dispatch(logout())
});


export default connect(mapStateToProps, mapDispatchToProps)(Presale);
