import React from "react";
import { connect } from "react-redux";
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeadingModule from '../components/Layout/HeadingComponent/Heading';
import Page404 from '../components/Page404';


const SuccessfulSwaps = (props) => {
  const copyString = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
  if (props.authedUser.authedUser.address === process.env.REACT_APP_ADMIN_ADDRESS.toLowerCase()){
    return (
        <>
            <section className="zl_account_support_page">
                <HeadingModule name={'Successful Swaps'} />
                <div class="limiter">
                            <div class="container-table100">
                                <div class="wrap-table100">
                                    <div class="table100">
                                        <table>
                                            <thead>
                                                <tr class="table100-head">
                                                <th class="column1">Amount</th>
                                                <th class="column2">Received Tx Hash</th>
                                                <th class="column3">Receiving Address</th>
                                                <th class="column4">Status</th>
                                                {/* <th class="column5">Quantity</th>
                                                <th class="column6">Total</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.swapTx.swapTx.filter(s => s.status === "Swapped").map((s, i) => {
                                                    return(
                                                        <tr>
                                                            <td class="column1">{s.amount}</td>
                                                            <td class="column2" onClick={() => copyString(s.tx_received)} >{s.tx_received.substring(0,5)}...{s.tx_received.substring(59,64)}</td>
                                                            <td class="column3">{s.generated_address}</td>
                                                            <td class="column4">{s.status}</td>
                                                            {/* <td class="column5">1</td>
                                                            <td class="column6">$999.00</td> */}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                {/* <div className="zl_SecureBackup_heading">
                    <h3>How can we help you?</h3>
                </div>
                <Form className="zl_account_support_input_list">
                    <Form.Group className="zl_account_support_input_items">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control type="email" placeholder="Type your email address here!" />
                    </Form.Group>
                    <Form.Group className="zl_account_support_input_items">
                        <Form.Label>Message</Form.Label>
                        <Form.Control type="text" placeholder="Type your Message here" />
                    </Form.Group>
                </Form>
                <div className="zl_securebackup_btn">
                    <Link to={'#'} className="mx-auto">Send</Link>
                </div> */}
            </section>
        </>
    );
  }
  else{
    return(
      <Page404 />
    );
  }
}

const mapStateToProps = (state) => ({
  authedUser: state.authedUser,
  swapTx: state.swapTx
})



export default connect(mapStateToProps, null)(SuccessfulSwaps);
